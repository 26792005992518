<template>
  <div>
    <a-card title="生产任务详情">
      <a-button
        slot="extra"
        type="primary"
        style="margin-right: 8px"
        ghost
        v-print="'#printContent'"
      >
        <a-icon type="printer" />打印</a-button
      >
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >
      <section id="printContent">
        <a-spin :spinning="loading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="生产编号">
              {{ info.number }}
            </a-descriptions-item>
            <a-descriptions-item label="经手人">
              {{ info.update_user_name }}
            </a-descriptions-item>
            <a-descriptions-item label="提交产品名称">
              {{ info.product_name }}
            </a-descriptions-item>
            <a-descriptions-item label="数量">
              {{ info.quantity }}
            </a-descriptions-item>
            <a-descriptions-item label="单价">
              {{ info.sale_price == 0 ? "暂定" : info.sale_price + "元" }}
            </a-descriptions-item>
            <a-descriptions-item label="下单用户">
              {{ info.bm_user_name }}
            </a-descriptions-item>
            <a-descriptions-item label="下单日期">
              {{ info.create_time }}
            </a-descriptions-item>
            <a-descriptions-item label="客户期望交货日期">
              {{ info.handle_date }}
            </a-descriptions-item>
            <a-descriptions-item label="用户备注">
              {{ info.remark }}
            </a-descriptions-item>
          </a-descriptions>
          <a-divider orientation="left" style="margin-top: 30px"
            >产品信息</a-divider
          >
          <div>
            <div style="margin-top: 16px">
              <a-table
                rowKey="id"
                size="middle"
                :columns="goods_columns"
                :data-source="goodsData"
                :pagination="false"
              >
              </a-table>
            </div>
          </div>

          <a-divider orientation="left" style="margin-top: 30px"
            >物料信息</a-divider
          >
          <div style="margin-top: 5px">
            <div style="margin-top: 16px">
              <a-table
                rowKey="id"
                size="middle"
                :columns="filteredMaterialsColumns"
                :data-source="materialsData"
                :pagination="false"
              >
                <div slot="action" slot-scope="value, item, index">
                  <a-button-group
                    v-if="purchase_button && !item.isTotal"
                    size="small"
                  >
                    <a-button
                      type="primary"
                      style="background-color: #52c41a; border-color: #52c41a"
                      @click="purchaseMaterial(item)"
                      >物料采购</a-button
                    >
                  </a-button-group>
                </div>
              </a-table>
            </div>
          </div>
        </a-spin>
      </section>

      <a-divider orientation="left">账单信息</a-divider>

      <div>
        <a-row gutter="16">
          <a-col :span="6">
            <a-form-model-item
              prop="worker_day"
              label="预估人工天数"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input-number v-model="worker_day" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item
              prop="package_cost"
              label="打包费"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input-number v-model="package_cost" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item
              prop="express_cost"
              label="物流费"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input-number v-model="express_cost" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item
              prop="other_amount"
              label="其他费用"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input-number v-model="other_amount" style="width: 100%" />
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-form-model-item
              prop="sale_price"
              label="零售价"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input-number
                v-model="sale_price"
                style="width: calc(100% - 20px)"
              />
              <span style="line-height: 32px; margin-left: 4px">元</span>
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-form-model-item
              prop="taskAmount"
              label="总成本"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input-number
                :value="taskAmount"
                :disabled="true"
                style="width: calc(100% - 20px)"
              />
              <span style="line-height: 32px; margin-left: 4px">元</span>
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-form-model-item
              prop="product_profit"
              label="单产品利润"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input-number
                :value="product_profit"
                :disabled="true"
                style="width: 100%"
              />
              <!-- {{ product_profit }}元 -->
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-form-model-item
              prop="order_profit"
              label="此订单利润"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input-number
                :value="order_profit"
                :disabled="true"
                style="width: 100%"
              />
              <!-- {{ order_profit }}元 -->
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item
              prop="order_profit_rate"
              label="此订单利润率"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input-number
                v-model.number="order_profit_rate"
                style="width: calc(100% - 20px)"
              />
              <span style="line-height: 32px; margin-left: 4px">%</span>
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-form-model-item
              label="实际生产数量"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input-number
                v-model.number="displayActualQuantity"
                style="width: 100%"
                :disabled="actualInput"
              />
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-form-model-item
              label="发货数量"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input-number
                v-model.number="displayShipmentQuantity"
                style="width: 100%"
                :disabled="shipInput"
              />
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-form-model-item
              label="计划交货时间"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-date-picker
                v-model="factory_eta"
                valueFormat="YYYY-MM-DD"
                style="width: 100%"
              />
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-form-model-item
              label="备注"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input v-model="bm_remark" style="width: 100%" />
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-form-model-item
              label="状态"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-select
                v-model="statues"
                style="width: 100%"
                @change="checkStatusChange"
              >
                <a-select-option
                  v-for="status in statusList"
                  :key="status"
                  :value="status"
                >
                  {{ statusMap[status] }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>

      <div style="margin-top: 32px">
        <a-popconfirm
          title="确认提交吗?"
          @confirm="update"
          :disabled="submit_button"
        >
          <a-button type="primary" :loading="loading" :disabled="submit_button"
            >提交</a-button
          >
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import {
  productionTaskDetail,
  productionOrderUpdate,
  GoodMaterialsRe,
} from "@/api/production";
import { purchaseOrderList } from "@/api/purchasing";
import { inventoriemsOption } from "@/api/option";
import { TaskProcessCreate } from "@/api/production";
import NP from "number-precision";
import moment from "moment";

export default {
  data() {
    return {
      loading: false,
      loading_re: false,
      worker_day: 0,
      express_cost: 0,
      package_cost: 0,
      other_amount: 0,
      worker_day_cost: 200, //工人每天费用
      sale_price: 0,
      bm_remark: "",
      actual_quantity: null,
      shipment_quantity: null,
      factory_eta: "",
      info: {},
      materialItems: [],
      goodItems: [],
      statues: "",
      currentStatus: "",
      client: null,
      order_price: 0,
      submit_button: false,
      material_button: false,
      purchaseOrder: [],
      pending_status: ["Analysis", "Purchase"],
      production_status: ["InProduction", "Complete", "Shipped"],
      validTransitions: {
        Analysis: ["Analysis", "Purchase", "InProduction", "Shipped", "Cancel"],
        Purchase: ["Purchase", "InProduction", "Cancel"],
        InProduction: ["InProduction", "Complete", "Cancel"],
        // Complete: ["Complete", "Shipped"],
        Complete:this.get_complete_status(),
        Shipped: this.get_shipped_status(),
        Cancel: this.get_cancel_status(),
      },
      statusMap: {
        Analysis: "待生产",
        Purchase: "物料采购",
        InProduction: "生产中",
        Complete: "生产完成",
        Shipped: "已发货",
        Cancel: "取消",
      },

      goods_columns: [
        {
          title: "产品名称",
          dataIndex: "goods_name",
          key: "goods_name",
          width: 150,
        },
        {
          title: "产品编号",
          dataIndex: "goods_number",
          key: "goods_number",
          width: 150,
        },
        {
          title: "库存",
          dataIndex: "inventory",
          key: "inventory",
          width: 150,
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],

      materials_columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "物料名称",
          dataIndex: "materials_name",
          key: "materials_name",
          width: 150,
        },
        {
          title: "物料编号",
          dataIndex: "materials_number",
          key: "materials_number",
          width: 150,
        },
        {
          title: "所需数量",
          dataIndex: "purchase_quantity",
          key: "purchase_quantity",
          width: 120,
        },
        {
          title: "单位",
          dataIndex: "unit_name",
          key: "unit_name",
          width: 80,
        },
        {
          title: "采购单价(元)",
          dataIndex: "purchase_price",
          key: "purchase_price",
          width: 120,
        },
        {
          title: "仓库库存",
          dataIndex: "materials_inventory",
          key: "materials_inventory",
          width: 80,
        },

        {
          title: "金额",
          dataIndex: "OneAmount",
          key: "OneAmount",
          width: 80,
          customRender: (value, item) => {
            if (item.isTotal) return value;
            value = NP.times(item.purchase_quantity, item.purchase_price);
            return item.id ? NP.round(value, 2) : "";
          },
        },
        {
          title: "剩余库存",
          dataIndex: "need_materials_inventory",
          key: "need_materials_inventory",
          width: 120,
          customRender: (value, item) => {
            if (item.id > 0) {
              value = NP.times(
                item.purchase_quantity,
                this.displayActualQuantity
              );
              value = NP.minus(item.materials_inventory, value);
              return item.id > 0 ? NP.round(value, 2) : "";
            } else {
              return "";
            }
          },
        },

        {
          title: "准备状态",
          dataIndex: "pr_status",
          key: "pr_status",
          width: 120,
          customRender: (value, item) => {
            if (item.id > 0) {
              value = NP.times(
                item.purchase_quantity,
                this.displayActualQuantity
              );
              need_inventory = NP.minus(item.materials_inventory, value);

              if (NP.round(need_inventory, 2) > 0) {
                return "库存充足";
              }

              if (NP.round(need_inventory, 2) == 0) {
                return "库存刚刚好";
              }

              if (this.purchaseOrder.length > 0) {
                for (let onePurchaseOrder of this.purchaseOrder) {
                  var materials_items =
                    onePurchaseOrder.purchase_materials_items.find(
                      (materials) => materials.materials === item.materials
                    );
                  if (materials_items) {
                    const eta = onePurchaseOrder.eta;
                    const today = new Date().toISOString().split("T")[0];
                    const isToday = eta === today;
                    const isPast = eta < today;
                    const className = isToday
                      ? "is-today"
                      : isPast
                      ? "is-past"
                      : "";
                    console.log("materials_items", materials_items);
                    var purchase_quantity = materials_items.purchase_quantity;
                    var need_inventory = NP.plus(
                      purchase_quantity,
                      need_inventory
                    );

                    if (need_inventory > 0) {
                      // return '采购中'
                      return {
                        children: (
                          <div class={className}>采购中,发货日期为{eta}</div>
                        ),
                        attrs: {},
                      };
                    } else {
                      return {
                        children: (
                          <div class={className}>
                            采购的库存不足，还需要{-need_inventory}
                          </div>
                        ),
                        attrs: {},
                      };
                    }
                  }
                  return "库存不足，还未采购";
                }
              } else {
                return "库存不足，还未采购";
              }
            }
          },
        },

        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 160,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },

  computed: {
    filteredMaterialsColumns() {
      // 需要去掉的列的 dataIndex
      const columnsToRemove = ["need_materials_inventory", "pr_status"];

      // 如果 currentStatus 为 "Analysis" 或 "Purchase"，保留所有列
      if (
        this.currentStatus === "Analysis" ||
        this.currentStatus === "Purchase"
      ) {
        return this.materials_columns;
      } else {
        // 过滤掉指定的列
        return this.materials_columns.filter(
          (column) => !columnsToRemove.includes(column.dataIndex)
        );
      }
    },
    displayActualQuantity: {
      get() {
        // 如果 actual_quantity 是 null，则返回 quantity，否则返回 actual_quantity
        return this.actual_quantity !== null
          ? this.actual_quantity
          : this.info.quantity;
      },
      set(newValue) {
        // 设置 actual_quantity 的值
        this.actual_quantity = newValue;
      },
    },

    displayShipmentQuantity: {
      get() {
        // 如果 actual_quantity 是 null，则返回 quantity，否则返回 actual_quantity
        return this.shipment_quantity !== null
          ? this.shipment_quantity
          : this.info.quantity;
      },
      set(newValue) {
        // 设置 actual_quantity 的值
        this.shipment_quantity = newValue;
      },
    },

    statusList() {
      return this.validTransitions[this.currentStatus];
    },
    actualInput() {
      // return true
      return !["Analysis", "Purchase"].includes(this.statues);
    },
    shipInput() {
      // return true
      return !["Analysis", "Purchase", "InProduction", "Complete"].includes(
        this.statues
      );
    },

    // use_quantity() {
    //   if (
    //     this.info.quantity != this.actual_quantity &&
    //     this.actual_quantity != null
    //   ) {
    //     return this.actual_quantity;
    //   } else {
    //     return this.info.quantity;
    //   }
    // },

    use_quantity() {
      if (this.currentStatus == "Shipped") {
        return this.displayShipmentQuantity;
      } else if (["Complete", "InProduction"].includes(this.currentStatus)) {
        return this.displayActualQuantity;
      } else {
        return this.info.quantity;
      }
    },

    sales() {
      if (this.use_quantity) {
        return NP.times(this.use_quantity, this.sale_price);
      }
      return 0;
    },
    //利润
    product_profit() {
      if (this.use_quantity) {
        return NP.divide(this.order_profit, this.use_quantity).toFixed(2);
      }
      return 0;
    },

    order_profit() {
      if (this.sale_price > 0) {
        return NP.minus(this.sales, this.taskAmount).toFixed(2);
      }
      return 0;
    },

    order_profit_rate: {
      get() {
        // 获取利润率
        if (this.sale_price > 0 && this.use_quantity > 0) {
          return ((this.order_profit / this.sales) * 100).toFixed(2);
        }
        return 0;
      },
      set(value) {
        // console.log(value)
        // 设置新的售价基于输入的利润率
        if (
          value === undefined ||
          this.taskAmount === undefined ||
          this.use_quantity === undefined
        ) {
          console.error("One of the required values is undefined");
          return; // 退出设置，防止错误
        }

        // 计算新的售价
        const adjustedValue = NP.divide(value, 100);
        const oneMinusValue = NP.minus(1, adjustedValue);
        if (oneMinusValue === 0) {
          console.error("Division by zero error");
          return; // 如果分母为0，退出设置，防止错误
        }
        const dividedTaskAmount = NP.divide(this.taskAmount, oneMinusValue);
        this.sale_price = NP.divide(
          dividedTaskAmount,
          this.use_quantity
        ).toFixed(2);
      },
    },

    oneAmount() {
      let oneAmount = 0;
      for (let item of this.materialItems) {
        let amount = NP.times(item.purchase_quantity, item.purchase_price);
        oneAmount = NP.plus(oneAmount, amount);
      }
      return oneAmount;
    },

    taskAmount() {
      if (typeof this.oneAmount !== "number") {
        return 0;
      }
      if (typeof this.use_quantity !== "number") {
        return 0;
      }

      let task_amount = NP.times(this.use_quantity, this.oneAmount);
      let worker_cost = NP.times(this.worker_day_cost, this.worker_day || 0);
      let taskAmount = NP.plus(
        task_amount,
        this.other_amount || 0,
        worker_cost || 0,
        this.package_cost || 0,
        this.express_cost || 0
      );
      return taskAmount;
    },

    purchase_button() {
      return this.statues == "Purchase";
    },

    add_button() {
      const is_pending_status = !this.pending_status.includes(this.statues);
      return is_pending_status;
    },

    goodsData() {
      return [...this.goodItems];
    },

    materialsData() {
      // 统计合计
      let totalQuantity = 0,
        one_amount = 0;
      for (let item of this.materialItems) {
        totalQuantity = NP.plus(totalQuantity, item.purchase_quantity);
        let amount_cost = NP.times(item.purchase_quantity, item.purchase_price);
        one_amount = NP.plus(one_amount, amount_cost);
      }

      // console.log(this.materialItems)
      return [
        ...this.materialItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          purchase_quantity: totalQuantity,
          OneAmount: one_amount,
        },
      ];
    },

    calculatedInventory() {
      return this.materialItems.map((item) => {
        if (item.id > 0) {
          let value = NP.times(
            item.purchase_quantity,
            this.displayActualQuantity
          );
          value = NP.minus(item.materials_inventory, value);
          return NP.round(value, 2);
        }
        return "";
      });
    },
  },

  methods: {
    get_cancel_status() {
      if (["jason", "Julie"].includes(this.$store.state.user.username)) {
        return ["Cancel", "Analysis", "Purchase"];
      }
      return ["Cancel"];
    },

    get_complete_status() {
      if (["jason", "Julie"].includes(this.$store.state.user.username)) {
        return ["Complete", "Shipped","Cancel"];
      }
      return  ["Complete", "Shipped"];
    },

    get_shipped_status() {
      if (["jason", "Julie"].includes(this.$store.state.user.username)) {
        return ["Shipped", "Cancel"];
      }
      return ["Shipped"];
    },

    checkStatusChange(newStatus) {
      var hasNegative = this.calculatedInventory.some((element) => element < 0);
      // console.log("hasNegative", hasNegative && this.production_status.includes(newStatus));
      // console.log(hasNegative,this.production_status.includes(newStatus));
      if (
        this.pending_status.includes(this.currentStatus) &&
        this.production_status.includes(newStatus) &&
        hasNegative
      ) {
        this.$message.error(`物料库存不足,无法更新为${newStatus}状态`);
        this.statues = this.currentStatus;
        return false;
      } else {
        this.statues = newStatus;
        return true;
      }
    },

    get_purchase_orders(production_id) {
      purchaseOrderList({ is_void: 0, production: production_id }).then(
        (data) => {
          this.purchaseOrder = data.results;
        }
      );
    },

    getMaterialItem(id) {
      const searchForm = {
        goods: id,
      };
      // console.log('searchForm',searchForm)
      GoodMaterialsRe(searchForm)
        .then((data) => {
          const material_goods_quantity = data.results;
          const material_ids = data.results
            .map((item) => item.material)
            .join(",");
          if (data.results.length === 0) {
            // 如果结果是空数组，执行相关操作
            console.log("No materials found for the given ID.");
            this.materialItems = [];
            this.loading = false; // 停止加载指示器
            this.material_button = false;
            return; // 提前退出函数
          }
          // console.log(material_ids)

          const inventoriemSearchForm = {
            materials__in: material_ids,
            warehouse: 1,
          };
          inventoriemsOption(inventoriemSearchForm)
            .then((data) => {
              this.materialItems = data.results.map((item) => ({
                id: item.id, // 可以保留原始ID或不包含
                materials: item.materials,
                materials_number: item.materials_number,
                materials_name: item.materials_name,
                materials_spec: item.materials_spec,
                purchase_price: item.purchase_price,
                materials_inventory: item.total_quantity,
                purchase_quantity: material_goods_quantity.find(
                  (goods) => goods.material === item.materials
                ).material_quantity,
                total_quantity: item.total_quantity,
                unit_name: item.unit_name,
              }));
            })
            .finally(() => {
              // this.loading = false;
              this.material_button = false;
            });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    purchaseMaterial(item) {
      this.$router.push({
        path: "/purchasing/purchase_create",
        query: {
          //只能传递简单的键值对
          production_id: this.info.id,
          production_number: this.info.number,
          item: item,
        },
      });
    },

    update_process() {
      console.log("status", this.statues, this.currentStatus);
      if (
        !["Complete", "Shipped"].includes(this.currentStatus) &&
        ["Complete", "Shipped"].includes(this.statues)
      ) {
        console.log("调用了");
        // 执行当状态不包括 'Complete' 或 'Shipped'，但目标状态包括 'Complete' 或 'Shipped' 时的逻辑
        var formatData = {
          production_task: this.info.id,
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
          worker_name: "默认工人",
          quantity_all: this.use_quantity,
          is_all_temp: true,
          is_all: true,
        };
        // console.log(formatData)
        //表格编辑都是创建新的
        TaskProcessCreate(formatData).then((data) => {
          this.$message.success("更改任务状态成功");
        });
      }
    },

    update() {
      console.log("更新生产单信息");
      this.loading = true;
      let formData = {
        // ...this.form,
        // purchase_account_items,
        id: this.info.id,
        statues: this.statues,
        goods:
          this.goodItems && this.goodItems.length > 0
            ? this.goodItems[0].id
            : null,

        other_amount: this.other_amount,
        worker_day: this.worker_day,
        package_cost: this.package_cost,
        express_cost: this.express_cost,
        bm_remark: this.bm_remark,
        factory_eta: this.factory_eta,
        actual_quantity: this.actual_quantity,
        shipment_quantity: this.shipment_quantity,
        client: this.client,
      };
      // console.log(formData)

      if (this.goodItems.length === 0 || this.materialItems.length === 0) {
        if (this.production_status.includes(this.statues)) {
          this.$message.error("没有产品信息或者物料信息,无效提交");
          this.loading = false;
          return;
        }
      }

      this.update_process();
      productionOrderUpdate(formData)
        .then((data) => {
          this.currentStatus = data.statues;
          this.$message.success("更新成功");
        })
        .catch((error) => {
          // 处理错误的逻辑
          // 检查错误对象并提取服务器返回的错误信息
          const errorMessage =
            error.response && error.response.data
              ? error.response.data
              : "未知错误"; // 提供一个默认错误消息

          this.$message.error("更新失败: " + errorMessage);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    initData() {
      this.loading = true;
      // this.resetForm();
      productionTaskDetail({ id: this.$route.query.search }).then((data) => {
        // console.log('data',data)
        this.info = data;
        this.other_amount = data.other_amount;
        this.worker_day = data.worker_day;
        this.package_cost = data.package_cost;
        this.express_cost = data.express_cost;
        this.bm_remark = data.bm_remark;
        this.factory_eta = data.factory_eta;
        this.actual_quantity = data.actual_quantity;
        this.shipment_quantity = data.shipment_quantity;
        this.statues = data.statues;
        this.currentStatus = data.statues;
        this.client = data.client;
        this.order_price = data.sale_price;
        this.sale_price = data.sale_price;
        if (!data.goods) {
          console.log("此单没有BM产品ID,请检查此单");
          this.submit_button = true;
          this.$message.error("此单没有BM产品ID,请检查后进行补充");
        } else if (!data.client) {
          this.$message.error("此单没有BM产品客户ID,请检查后进行补充");
          this.submit_button = true;
        } else {
          this.goodItems = [
            {
              id: data.goods,
              goods_name: data.goods_name,
              goods_number: data.goods_number,
              retail_price: data.retail_price,
              level_price1: data.level_price1,
              level_price2: data.level_price2,
              level_price3: data.level_price3,
              inventory: data.inventory,
            },
          ];
          this.getMaterialItem(data.goods);
        }
      });

      this.get_purchase_orders(this.$route.query.search);
    },
  },

  watch: {
    // 当产品变化，将 this.sale_price
  },

  mounted() {
    console.log("Hello");
    this.initData();
  },
};
</script>
<style></style>
